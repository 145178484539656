<template>
  <div
    class="background-image position-relative d-flex align-items-start flex-column"
  >
    <div class="d-flex justify-content-center flex-row mt-3">
      <div class="w-auto d-flex flex-row gap-3">
        <img class="bmw-logo" src="../assets/images/bmw_logo.svg"  alt=""/>
        <img class="mini-logo" src="../assets/images/mini_logo.svg"  alt=""/>
      </div>
    </div>
    <div
      class="my-auto page-content p-3 text-center fs-5"
      jp-test-el="thank-text"
    >
      {{ $t("thankText") }}
    </div>
    <div class="page-footer p-3">
      <div class="d-grid gap-2 col-md-6 col-lg-4 mx-md-auto">
        <router-link
          :to="`/configuration/${$route.params.configurationGuid}/questions/${$store.getters['questions/getFirstQuestionId']}`"
          class="btn btn-primary rounded-0"
          type="button"
        >
          {{ $t("startSurvey") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeMount () {
    this.$store.dispatch('questions/getQuestions', this.$route.params.configurationGuid)
  }
}
</script>

<style lang="scss" scoped>
.bmw-logo {
  max-width: 5rem;
}
.mini-logo {
  max-width: 9rem;
}
.background-image {
  height: 100%;

  > div {
    z-index: 2;
    width: 100vw;
  }

  @media (min-width: 800px) {
    .page-content {
      padding: 0 10rem !important;
    }
  }
  @media (min-width: 1280px) {
    .page-content {
      padding: 0 20rem !important;
    }
  }
}
</style>
